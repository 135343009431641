import { idID } from '@mui/material/locale';
import { DATA_STATUS } from '../utils/constants';
import api from './configs/axiosConfig';

const enquiryMaster = '/enquiry/';

const masterStatus = '/moduleDetails/miscellaneous/searchByName/' + DATA_STATUS;

export const EnquiryListAPI = {
  getEnquiry: async function (page, pageSize) {
    const response = await api.get(
      `/enquiry?page=${page}&pageSize=${pageSize}`
    );
    return response;
  },
  updateEnquiryData: async function (id, data, header) {
    const response = await api.put(enquiryMaster + id, data, header);
    return response;
  },
  getStatusEnquiryData: async function () {
    const response = await api.get(masterStatus);
    return response;
  },
};
