import {
  GET_B2B_DATA,
  GET_B2B_USER,
  SET_B2B_TOTAL_DATA,
  SET_SEARCHED_B2B_DATA,
  SET_B2B_CURRENT_PAGE,
} from '../types';
const initial = {
  b2bList: [],
  b2bUser: [],
  b2bTotalList: 0,
  searchedB2bData: [],
  currentPage: 1,
  loading: true,
};
export default function b2bReducer(state = initial, action) {
  switch (action.type) {
    case GET_B2B_DATA:
      return { ...state, b2bList: action.payload, loading: false };
    case SET_B2B_TOTAL_DATA:
      return { ...state, b2bTotalList: action.payload, loading: false };
    case SET_SEARCHED_B2B_DATA:
      return { ...state, searchedB2bData: action.payload, loading: false };
    case SET_B2B_CURRENT_PAGE:
      return { ...state, currentPage: action.payload, loading: false };
    case GET_B2B_USER:
      return { ...state, b2bUser: action.payload, loading: false };
    default:
      return state;
  }
}
