/* eslint-disable no-useless-concat */
import React, { useCallback, useEffect, useState } from 'react';
// import CommonTable from "../../component/CommonTable";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  ListSubheader,
  Menu,
  MenuItem,
  Modal,
  Pagination,
  Popover,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import moment from 'moment';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { ReactComponent as OptionsIcon } from '../../assets/options-icon.svg';
import CustomPagination from '../../component/CustomPagination/CustomPagination';

// import List from 'react-virtualized/List';
// import EnquiryUpdateStatusModal from "./EnquiryUpdateStatusModal";
import { setHeaderTitle } from '../../store/actions/rootActions';

import { useHistory } from 'react-router-dom';
import SideBarContent from '../../component/SideBarContent/SideBarContent';
import { connect } from 'react-redux';

import {
  getVendors,
  getVendorDetails,
  deleteVendor,
  searchVendors,
  downlaodExcelDoc,
} from './redux/actions/b2b-action';
import { GridDeleteIcon } from '@mui/x-data-grid';
import { pushMessage } from '../../component/MessageBox/redux/actions/message-box-action';
import { decryptParams, encryptParams } from '../../utils/helperFunctions';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

function B2BProfile({
  getVendors,
  getVendorDetails,
  b2bList,
  b2bTotalList,
  setHeaderTitle,
  deleteVendor,
  pushMessage,
  currentPage,
  searchVendors,
  downlaodExcelDoc,
}) {
  console.log('b2bList', b2bList);

  const [filteredB2bList, setFilteredB2bList] = useState(b2bList);
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(20); // Since data is static, we don't need to change rowsPerPage
  const [currentB2bUser, setCurrentb2bUser] = useState();
  // const totalPages = Math.ceil(b2bTotalList / rowsPerPage);

  const handleChangePage = (newPage) => {
    setPage(newPage);
    getVendors(newPage, rowsPerPage);
  };

  const deleteUser = (user_id) => {
    deleteVendor(
      user_id,
      (res) => {
        getVendors(page, rowsPerPage);
        pushMessage(res);
      },
      (err) => {
        pushMessage(err);
      }
    );
  };
  useEffect(() => {
    console.log('====b2bList787 here', b2bList);
  }, [b2bList]);
  useEffect(() => {
    console.log('searchText', searchText);

    if (searchText === '') getVendors(currentPage, rowsPerPage);
  }, [searchText]);
  // useEffect(() => {
  //   setPage(currentPage - 1);
  //   // setFilteredB2bList(b2bList);
  // }, [b2bList]);

  const history = useHistory();

  const handleCreateButtonClick = () => {
    history.push('/create-users'); // Navigate to Createrole page
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, row) => {
    setAnchorEl(event?.currentTarget);
    setCurrentb2bUser(row);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const options = ['View', 'Delete'];

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const statusClasses = {
    Approved: 'text-status-active',
    Pending: 'text-status-pending',
    Rejected: 'text-status-inactive',
  };
  function convertToQueryString(arr) {
    const statusMap = {
      approved: 'approved',
      pending: 'pending',
      rejected: 'rejected',
    };

    const businessTypeMap = {
      business: 2,
      freelancer: 3,
    };

    const queryParams = [];

    arr.forEach((item) => {
      const status = statusMap[item] || undefined;
      const businessType = businessTypeMap[item] || undefined;

      if (status) {
        queryParams.push(`status=${status}`);
      }

      if (businessType) {
        queryParams.push(`businessType=${businessType}`);
      }
    });

    const uniqueQueryParams = [...new Set(queryParams)];
    return uniqueQueryParams.length > 0
      ? `?${uniqueQueryParams.join('&')}`
      : '';
  }
  function toCamelCase(str) {
    return str
      ?.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word?.toUpperCase() : word?.toLowerCase();
      })
      ?.replace(/\s+/g, '');
  }
  const [openDelete, setOpenDelete] = useState(false);
  const [userId, setUserId] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleSelectionChange = (event) => {
    const value = event.target.value;
    console.log('handleSelectionChange', value);

    setSelectedOptions(value);
    applyFilters(value);
  };

  const applyFilters = (selectedOptions) => {
    if (
      !selectedOptions.length ||
      selectedOptions.every((option) => option.trim() === '')
    ) {
      setFilteredB2bList(b2bList);
      // setSelectedOptions('All');
      return;
    }

    const newFilteredData = b2bList.filter((user) => {
      const statusMatch = selectedOptions.includes(user?.status?.toLowerCase());
      const registrationTypeMatch = selectedOptions.includes(
        user.registration_type?.toLowerCase()
      );
      // console.log('newFilteredData', statusMatch, registrationTypeMatch);
      return registrationTypeMatch || statusMatch;
    });

    setFilteredB2bList(newFilteredData);
  };

  const [debouncedSearchText, setDebouncedSearchText] = useState('');
  const [loading, setLoading] = useState(false);

  const debounceSearch = useCallback((value) => {
    const handler = setTimeout(() => {
      setDebouncedSearchText(value);
      setLoading(false);
    }, 500); // 500 ms debounce delay

    return () => {
      clearTimeout(handler);
      setLoading(false);
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    const cancelDebounce = debounceSearch(searchText);
    return cancelDebounce;
  }, [searchText, debounceSearch]);

  useEffect(() => {
    if (debouncedSearchText !== '') {
      searchVendors(
        1,
        rowsPerPage,
        debouncedSearchText,
        (r) => {},
        () => {}
      );
    } else {
      getVendors(1, rowsPerPage);
    }
  }, [debouncedSearchText, rowsPerPage, searchVendors, getVendors]);
  const handleCreateExcelClick = async () => {
    if (selectedOptions.length === 0) {
      pushMessage('Select Atleast one option');
    } else {
      const query = convertToQueryString(selectedOptions);
      await downlaodExcelDoc(query, pushMessage, (res) => {
        setSelectedOptions([]);
        // pushMessage(res?.message);
        // console.log('downlaod pdf result', res);
      });
    }
  };
  return (
    <SideBarContent>
      <Modal
        open={openDelete}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Elynker
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are you sure you want to delete this Profile?
          </Typography>
          <div style={{ float: 'right' }}>
            <Button
              onClick={() => {
                setOpenDelete(false);
                deleteUser(userId);
              }}
            >
              Yes
            </Button>
            <Button
              onClick={() => {
                setOpenDelete(false);
              }}
            >
              No
            </Button>
          </div>
        </Box>
      </Modal>
      <Box className="custom-card-bg">
        <Box
          className="mb-4"
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Grid
            container
            spacing={2}
            className="align-items-center justify-content-between"
          >
            <Grid item>
              <TextField
                sx={{ height: '50px', width: '400px', borderRadius: '6px' }}
                variant="outlined"
                placeholder="Search here..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <>
                      <Search
                        sx={{
                          width: '1em',
                          height: '1em',
                          marginRight: '8px',
                          color: '#864FFD',
                        }}
                        color="action"
                      />
                      {loading && (
                        <CircularProgress
                          size={20}
                          sx={{ color: '#864FFD' }}
                          thickness={5}
                        />
                      )}
                    </>
                  ),
                }}
              />
            </Grid>
            <Grid style={{ width: 400 }}>
              <FormControl
                fullWidth
                sx={{ my: 1, maxWidth: '400px', flex: 1, flexDirection: 'row' }}
              >
                <Select
                  multiple
                  sx={{ width: 200 }}
                  value={selectedOptions}
                  onChange={handleSelectionChange}
                  placeholder="Select Options"
                  renderValue={(selected) => {
                    return selected.join(', ');
                  }}
                  // inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem value="Select from list">Select from list</MenuItem>
                  <ListSubheader>Status</ListSubheader>
                  <MenuItem value="approved">Approved</MenuItem>
                  <MenuItem value="rejected">Rejected</MenuItem>
                  <ListSubheader>User Type</ListSubheader>
                  <MenuItem value="business">Business</MenuItem>
                  <MenuItem value="freelancer">Freelancer</MenuItem>
                </Select>
                <Button
                  className="btn-create"
                  sx={{ width: 200, marginLeft: 3 }}
                  onClick={handleCreateExcelClick}
                >
                  Download profile
                </Button>
              </FormControl>
            </Grid>
          </Grid>
        </Box>

        <Paper sx={{ width: '100%' }}>
          <TableContainer sx={{ maxHeight: '450px' }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>User ID</TableCell>
                  <TableCell>Registration Date</TableCell>
                  <TableCell>Company Name/ Roles</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Type</TableCell>
                  {/* <TableCell>Email</TableCell> */}
                  <TableCell>Phone Number</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {b2bList.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row?.user?.code}</TableCell>
                    <TableCell>
                      {moment(row.createdAt).format('DD-MM-YYYY')}
                    </TableCell>
                    <TableCell>
                      {row?.registration_type === 'Business'
                        ? row?.company_name
                        : row?.freelancer_role}
                    </TableCell>
                    <TableCell>
                      <Typography
                        sx={{ color: '#393939' }}
                        className="fs-14 font-gotham"
                      >
                        {row?.name}
                      </Typography>
                      <Typography
                        sx={{ color: '#868686' }}
                        className="fs-13 font-gotham"
                      >
                        {row?.user?.email}
                      </Typography>
                    </TableCell>
                    <TableCell>{row?.registration_type}</TableCell>
                    {/* <TableCell>
                                            {row.user.email}
                                        </TableCell> */}
                    <TableCell>{row?.user?.mobile_number}</TableCell>
                    <TableCell>{row?.city}</TableCell>

                    <TableCell align="center">
                      {/* <Typography className={row.status === 'Approved' ? 'text-status-active' : 'text-status-inactive'}>{row.status}</Typography> */}
                      <Typography
                        className={statusClasses[toCamelCase(row?.status)]}
                      >
                        {toCamelCase(row?.status)}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Button onClick={(e) => handleClick(e, row)}>
                        {<OptionsIcon />}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {options.map((option, optionIdx) => (
              <MenuItem
                // elevation={0}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                sx={{
                  boxShadow:
                    '0px 0px 0px 1px rgba(0,0,0,-1.8), 0px 0px 0px 0px rgba(0,0,0,0), 0px 0px 4px -1px rgba(0,0,0,0.10) !important',
                }}
                key={option}
                // selected={option === 'View'}
                onClick={() => {
                  if (optionIdx === 0) {
                    handleClose();
                    handleOpenModal();
                    getVendorDetails(currentB2bUser.id);
                    console.log('=====currentB2bUser click', currentB2bUser);

                    const user_id = currentB2bUser.user.id;
                    const reg_id = currentB2bUser.id;
                    if (currentB2bUser.registration_type === 'Freelancer') {
                      setHeaderTitle('Freelancer');
                      history.push({
                        pathname: `/freelancer-profile-view-reason/${reg_id}`,
                        state: { user_id },
                      });
                    } else {
                      setHeaderTitle('Business');
                      history.push({
                        pathname: `/b2b-profile-view/${reg_id}`,
                        state: { user_id },
                      });
                    }
                  }
                  if (optionIdx === 1) {
                    console.log('option index 1');
                    setUserId(currentB2bUser?.user.id);
                    handleClose();
                    setOpenDelete(true);
                  }
                }}
              >
                {option}
              </MenuItem>
            ))}
          </Menu>
          {/* <EnquiryUpdateStatusModal open={openModal} onClose={handleCloseModal} /> */}
          <CustomPagination
            page={currentPage}
            count={b2bTotalList}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
          />
        </Paper>
      </Box>
    </SideBarContent>
  );
}
const mapStateToProps = ({ b2bUser }) => {
  console.log('====mapStateToProps b2bUser', b2bUser);
  return {
    b2bList: b2bUser.b2bList,
    b2bTotalList: b2bUser.b2bTotalList,
    currentPage: b2bUser.currentPage,
    b2bloading: b2bUser.loading,
  };
};

export default connect(mapStateToProps, {
  getVendors,
  getVendorDetails,
  setHeaderTitle,
  deleteVendor,
  pushMessage,
  searchVendors,
  downlaodExcelDoc,
})(B2BProfile);
