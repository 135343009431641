import api from './configs/axiosConfig';

const UserMaster = '/users/';

const UserMasterAdmin = '/admin/users/';

export const UserMasterAPI = {
  getUserMaster: async function (page, pageSize) {
    const response = await api.get(`/users?page=${page}&pageSize=${pageSize}`);
    return response;
  },

  postUserMaster: async function (data, header) {
    const response = await api.post(UserMasterAdmin, data, header);
    return response;
  },

  updateUserMaster: async function (id, data, header) {
    const response = await api.put(UserMasterAdmin + id, data, header);
    return response;
  },

  getUserMasterById: async function (id) {
    const response = await api.get(UserMaster + id);
    return response;
  },
};
