import axios from 'axios';
import { Vendors } from '../../../../apis/vendorApi';
import { SET_LOADING } from '../../../../store/types';
import {
  GET_B2B_DATA,
  GET_B2B_USER,
  SET_B2B_TOTAL_DATA,
  SET_SEARCHED_B2B_DATA,
  SET_B2B_CURRENT_PAGE,
} from '../types';
import FileSaver from 'file-saver';
import api from '../../../../apis/configs/axiosConfig';
export const getVendors = (page, pageSize) => async (dispatch) => {
  try {
    const vendorType = 0;
    dispatch({ type: SET_LOADING, payload: true });
    const response_vendor = await Vendors.GetVendors(
      vendorType,
      page,
      pageSize
    );
    if (response_vendor.status === 200) {
      // console.log('record', response_vendor?.data?.data || [].length);

      dispatch({ type: GET_B2B_DATA, payload: response_vendor?.data?.data });
      dispatch({
        type: SET_B2B_TOTAL_DATA,
        payload: response_vendor?.data?.totalRecords,
      });
      dispatch({
        type: SET_B2B_CURRENT_PAGE,
        payload: page,
      });

      dispatch({ type: SET_LOADING, payload: false });
    } else {
      dispatch({ type: SET_LOADING, payload: false });
    }
  } catch (error) {
    dispatch({ type: SET_LOADING, payload: false });
  }
};
export const setAppLoading =
  (status = true) =>
  (dispatch) =>
    dispatch({ type: SET_LOADING, payload: status });
export const searchVendors =
  (page, pageSize, search_text, sucess, failure) => async (dispatch) => {
    try {
      const vendorType = 0;
      // dispatch({ type: SET_LOADING, payload: true });
      const response_vendor = await Vendors.SearchVendors(
        vendorType,
        page,
        pageSize,
        search_text
      );
      if (response_vendor.status === 200) {
        dispatch({ type: GET_B2B_DATA, payload: response_vendor?.data.data });
        dispatch({
          type: SET_B2B_TOTAL_DATA,
          payload: response_vendor?.data?.totalRecords,
        });
        dispatch({
          type: SET_B2B_CURRENT_PAGE,
          payload: page,
        });

        // dispatch({ type: SET_LOADING, payload: false });
        sucess(response_vendor?.data.data);
      } else {
        // dispatch({ type: SET_LOADING, payload: false });
        failure([]);
      }
    } catch (error) {
      // dispatch({ type: SET_LOADING, payload: false });
      dispatch({
        type: SET_B2B_CURRENT_PAGE,
        payload: [],
      });
      failure([]);
    }
  };
function removeLastApiSegment(url) {
  if (url.endsWith('/api')) {
    return url.slice(0, -4);
  }
  return url;
}

const downloadExcel = async (excelUrl) => {
  const filename = 'downloaded.xlsx';

  try {
    const base_url = api.getUri();
    console.log('base url', base_url);
    const fileUrl = removeLastApiSegment(base_url);
    console.log('file url', fileUrl);

    const response = await axios.get(fileUrl + excelUrl, {
      responseType: 'blob',
    });

    FileSaver.saveAs(response.data, filename);
  } catch (error) {
    console.error('Error downloading Excel file:', error);
  }
};
export const downlaodExcelDoc =
  (query, pushMessage, reponse = () => {}, error = () => {}) =>
  async (dispatch) => {
    try {
      console.log('downlaodPdf', query);

      dispatch({ type: SET_LOADING, payload: true });
      const response_vendor = await Vendors.DownloadPdf(query);
      if (response_vendor.status === 200) {
        dispatch({ type: SET_LOADING, payload: false });
        pushMessage(response_vendor.data?.message);
        downloadExcel(response_vendor.data?.url);
        reponse(response_vendor.data);
      } else {
        dispatch({ type: SET_LOADING, payload: false });
        error([]);
      }
    } catch (err) {
      dispatch({ type: SET_LOADING, payload: false });
      error([]);
    }
  };
export const getVendorDetails = (reg_id) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING, payload: true });

    const [vendorResult, socialLinksResult] = await Promise.allSettled([
      Vendors.GetVendorDetail(reg_id),
      Vendors.GetSocialLinks(reg_id),
    ]);
    if (
      vendorResult.status === 'fulfilled' &&
      vendorResult.value.status === 200
    ) {
      const socialLinksData =
        socialLinksResult.status === 'fulfilled' &&
        socialLinksResult.value.status === 200
          ? socialLinksResult.value.data.data
          : null;

      dispatch({
        type: GET_B2B_USER,
        payload: {
          ...vendorResult.value.data.data,
          socialLinks: socialLinksData,
        },
      });
    } else {
      console.error('Failed to fetch vendor details:', vendorResult);
    }
  } catch (error) {
    console.error('Unexpected error during API calls:', error);
  } finally {
    dispatch({ type: SET_LOADING, payload: false });
  }
};
export const updateVendorStatus =
  (reg_id, status, successCallback = () => {}) =>
  async (dispatch) => {
    const response_vendor = await Vendors.updateVendorStatus(reg_id, status);
    successCallback();
    // dispatch({ type: GET_B2B_USER, payload: response_vendor.data })
    dispatch({ type: SET_LOADING, payload: false });
  };

export const deleteVendor = (user_id, sucess, error) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING, payload: true });
    const response_vendor = await Vendors.deleteVendor(user_id);
    console.log('==response_vendor', response_vendor);
    sucess(response_vendor.data?.message);
    // dispatch({ type: GET_B2B_USER, payload: response_vendor.data })
    dispatch({ type: SET_LOADING, payload: false });
  } catch (error) {
    error(error);
    dispatch({ type: SET_LOADING, payload: false });
  }
};
//GetVendorDetail
