import { REQUIREMENT_DATA_STATUS } from '../utils/constants';
import api from './configs/axiosConfig';

const requirementMaster = '/customer/requirement/';
const masterStatus =
  '/moduleDetails/miscellaneous/searchByName/' + REQUIREMENT_DATA_STATUS;

export const RequirementListAPI = {
  getRequirement: async function (page, pageSize) {
    const response = await api.get(
      `/customer/requirement?page=${page}&pageSize=${pageSize}`
    );
    return response;
  },
  getStatusRequirementData: async function () {
    const response = await api.get(masterStatus);
    return response;
  },
  updateRequirementData: async function (id, data, header) {
    const response = await api.put(requirementMaster + id, data, header);
    return response;
  },
};
