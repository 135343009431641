import React, { useEffect, useState } from 'react';
// import CommonTable from "../../component/CommonTable";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  OutlinedInput,
  Pagination,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import CustomPagination from '../../component/CustomPagination/CustomPagination';
import SideBarContent from '../../component/SideBarContent/SideBarContent';
import { ReactComponent as ProductImage1 } from '../../assets/product1.svg';
import B2BRejectModal from './B2BRejectModal';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  getVendorDetails,
  updateVendorStatus,
} from './redux/actions/b2b-action';
import { ReactComponent as InfoBtn } from '../../assets/info-btn.svg';
import Popover from '@mui/material/Popover';
import { useHistory } from 'react-router-dom';
import { PRODUCT_IMAGE_BASE_URL } from '../../apis/endpoints';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { pushMessage } from '../../component/MessageBox/redux/actions/message-box-action';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { decryptParams } from '../../utils/helperFunctions';
import {
  SOCIAL_MEDIA_IMAGE_PATH,
  SOCIAL_MEDIA_URL,
} from '../../apis/configs/axiosConfig';

export function extractDocNameAndNumber(documents) {
  if (!documents || !Array.isArray(documents)) {
    console.error('Invalid input: documents must be an array');
    return [];
  }

  const result = [];

  documents.forEach((doc) => {
    const { document_name, document_number } = doc;
    result.push({ document_name, document_number });
  });

  return result;
}
export function getCategoryFromTitles(arrayOfObjects) {
  const titles = arrayOfObjects.map((item) => item.title);
  const category = titles?.join(', ');
  return category;
}

function B2BProfileView({
  b2bUser,
  updateVendorStatus,
  regId,
  status,
  rejected_reason,
  pushMessage,
  getVendorDetails,
}) {
  //const navigate=useNavigate();
  const isSellerWebsite = b2bUser?.seller_websites?.length > 0;
  const cardSharing = b2bUser?.card_sharing;
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10); // Since data is static, we don't need to change rowsPerPage
  const location = useLocation();
  const [reason, setReason] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const history = useHistory();
  const [popperText, setPopperText] = useState('');
  const [popper, setPopper] = React.useState(null);

  const { reg_id } = useParams();
  //   const { userId } = location.state;
  // console.log("=====B2BProfileView props", currentB2bUser);
  //name,user.mobile_number,user.mobile,company_name,about_company,products[0].title,business_details-contain array

  useEffect(() => {
    if (reg_id) {
      getVendorDetails(reg_id);
    }
  }, []);

  const {
    name,
    user, //user.mobile_number
    company_name,
    about_company,
    business_details,
    whatsapp_number,
    additional_detail1,
    additional_detail2,
    additional_detail3,
    address1,
    address2,
    pincode,
    socialLinks,
    city,
    state,
    registration_type,
    products,
    categories,
    seller_websites,
  } = b2bUser;
  let mobile_number, email, categoriList;
  if (user) {
    const { mobile_number: userMobileNumber, email: userEmail } = user;
    mobile_number = userMobileNumber;
    email = userEmail;
  } else {
    mobile_number = '';
    email = '';
  }
  if (categories) {
    categoriList = getCategoryFromTitles(categories);
  } else {
    categoriList = '';
  }

  const productType = registration_type === 3 ? 'Service' : 'Product';
  const businessDetails = extractDocNameAndNumber(business_details);
  const handleChangePage = (newPage) => {
    setPage(newPage);
  };
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  function updateVendorStatusDetails(status) {
    //approved or rejected
    //rejected_reason
    updateVendorStatus(regId, { status }, () => {
      pushMessage('Status Updated Successfully');
    });
  }
  function handleRejectSave() {
    // console.log('====handleRejectSave', reason);
    updateVendorStatus(
      regId,
      { status: 'rejected', rejected_reason: reason },
      () => {
        pushMessage('Rejected Successfully');
        history.goBack();
      }
    );
    setOpenModal(false);
  }
  const handleInfIconClick = (event) => {
    // setAnchorEl(rejected_reason);
    setAnchorEl(event.currentTarget);
  };

  const handleInfIconClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  function toCamelCase(str) {
    return (
      str ||
      ''
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
          return index === 0 ? word.toUpperCase() : word.toLowerCase();
        })
        .replace(/\s+/g, '')
    );
  }

  function truncateText(text = '', length = 50) {
    if (text?.length <= length) {
      return text || '';
    } else if (text?.length == undefined) {
      return text;
    }
    return text?.substr(0, length) + '\u2026' || '';
  }

  const handleClickPopper = (event, about_company) => {
    setPopper(event.currentTarget);
    setPopperText(about_company);
  };
  const handleClosePopper = () => {
    setPopper(null);
    setPopperText('');
  };

  const openPopper = Boolean(popper);
  const idd = openPopper ? 'simple-popover' : undefined;

  //default_image
  const ac = truncateText(about_company);
  console.log(typeof ac);
  return (
    <SideBarContent>
      <Box className="custom-card-bg">
        <Box className="mb-4">
          <Box className="d-flex justify-content-between mb-4 align-items-center">
            {status && (
              <div className="ribbon mb-5">
                {/* <a href="#">{toCamelCase(status)}</a>
                 */}
                <a className={'' + status} href="#">
                  {toCamelCase(status)}
                </a>
              </div>
            )}
            {status === 'pending' && (
              <Box className="d-flex justify-content-end mb-4">
                <Button
                  className="btn-approve me-3"
                  onClick={() => {
                    updateVendorStatusDetails('approved');
                    history.goBack();
                  }}
                >
                  Approve
                </Button>
                {/* <Button className="btn-hold me-3">Hold</Button> */}
                <Button className="btn-reject" onClick={handleOpenModal}>
                  Reject
                </Button>
                <B2BRejectModal
                  handleSave={handleRejectSave}
                  open={openModal}
                  reason={reason}
                  setReason={setReason}
                  onClose={handleCloseModal}
                />
              </Box>
            )}
            {status === 'rejected' && (
              <Box className="d-flex justify-content-end mb-4">
                <Button
                  className="btn-approve me-3"
                  onClick={() => {
                    updateVendorStatusDetails('approved');
                    history.goBack();
                  }}
                >
                  Approve
                </Button>

                <Button
                  className="btn-info"
                  aria-describedby={id}
                  variant="contained"
                  onClick={handleInfIconClick}
                >
                  <InfoBtn />
                </Button>
              </Box>
            )}
            {status === 'approved' && (
              <Box className="d-flex justify-content-end mb-4">
                <Button className="btn-reject" onClick={handleOpenModal}>
                  Reject
                </Button>
                <B2BRejectModal
                  handleSave={handleRejectSave}
                  open={openModal}
                  reason={reason}
                  setReason={setReason}
                  onClose={handleCloseModal}
                />
              </Box>
            )}
          </Box>

          <Box className="mb-3">
            <Typography className="fs-20 fw-bold text-black font-skmodernist">
              Personal Details
            </Typography>
            <Divider className="personal-divider my-3"></Divider>
            <Grid container columnSpacing={4} rowSpacing={4}>
              <Grid
                item
                md={6}
                className="d-flex justify-content-between align-items-start"
              >
                <InputLabel className="text-start w-50 fs-15 text-black font-skmodernist fw-bold">
                  Name
                </InputLabel>
                <Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">
                  {name}
                </Typography>
              </Grid>

              <Grid
                item
                md={6}
                className="d-flex justify-content-between align-items-start"
              >
                <InputLabel className="text-start w-50 fs-15 text-black font-skmodernist fw-bold">
                  Mobile No.
                </InputLabel>
                <Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">
                  {mobile_number}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                className="d-flex justify-content-between align-items-start"
              >
                <InputLabel className="text-start w-50 fs-15 text-black font-skmodernist fw-bold">
                  Email
                </InputLabel>
                <Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">
                  {email}
                </Typography>
              </Grid>
            </Grid>

            <Divider className="main-divider my-3"></Divider>
            <Grid
              container
              columnSpacing={4}
              rowSpacing={4}
              className="align-items-start"
            >
              <Grid
                item
                md={6}
                className="d-flex justify-content-between align-items-start"
              >
                <InputLabel className="text-start w-50 fs-15 text-black font-skmodernist fw-bold">
                  Company Name
                </InputLabel>
                <Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">
                  {company_name}
                </Typography>
              </Grid>

              <Grid
                item
                md={6}
                className="d-flex justify-content-between align-items-start"
              >
                <InputLabel className="text-start w-50 fs-15 text-black font-skmodernist fw-bold">
                  About Company
                </InputLabel>
                <Typography
                  className="text-grey w-50 fs-15 text-grey font-skmodernist d-flex"
                  sx={{
                    whiteSpace: 'nowrap', // Ensures text stays on one line
                    overflow: 'hidden', // Prevents overflow if text is too long
                    textOverflow: 'ellipsis', // Adds an ellipsis (...) if text overflows
                  }}
                >
                  <span style={{ width: 'auto', display: 'inline-block' }}>
                    {truncateText(about_company, 40)}
                  </span>
                  {about_company?.length > 40 && (
                    <Button
                      aria-describedby={idd}
                      className="ps-2 justify-content-start btn-tooltip"
                      onClick={(e) => handleClickPopper(e, about_company)}
                    >
                      <InfoOutlinedIcon
                        sx={{ width: '19px', height: '19px', color: '#864FFD' }}
                      />
                    </Button>
                  )}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                className="d-flex justify-content-between align-items-start"
              >
                <InputLabel className="text-start w-50 fs-15 text-black font-skmodernist fw-bold">
                  Product Category
                </InputLabel>
                <Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">
                  {categoriList}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                className="d-flex justify-content-between align-items-start"
              >
                <InputLabel className="text-start w-50 fs-15 text-black font-skmodernist fw-bold">
                  WhatsApp number
                </InputLabel>
                <Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">
                  {whatsapp_number}
                </Typography>
              </Grid>
              {/* <Grid
                item
                md={6}
                className="d-flex justify-content-between align-items-start"
              >
                <InputLabel className="text-start w-50 fs-15 text-black font-skmodernist fw-bold">
                  Key details
                </InputLabel>
                <Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">
                  {additional_detail1}
                </Typography>
                <Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">
                  {additional_detail2}
                </Typography>
                <Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">
                  {additional_detail3}
                </Typography>
              </Grid> */}
              <Grid
                item
                md={12}
                className="d-flex justify-content-start align-items-start"
              >
                <InputLabel className="text-start w-25 fs-15 text-black font-skmodernist fw-bold">
                  Company Address
                </InputLabel>
                <Typography className="text-grey w-75 fs-15 text-grey font-skmodernist">
                  {address1}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                className="d-flex justify-content-between align-items-start"
              >
                <InputLabel className="text-start w-50 fs-15 text-black font-skmodernist fw-bold">
                  City/District
                </InputLabel>
                <Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">
                  {city}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                className="d-flex justify-content-between align-items-start"
              >
                <InputLabel className="text-start w-50 fs-15 text-black font-skmodernist fw-bold">
                  State
                </InputLabel>
                <Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">
                  {state}
                </Typography>
              </Grid>
              <Grid
                item
                md={12}
                className="d-flex justify-content-start align-items-start"
              >
                <Grid item md={4} className="d-flex align-items-start">
                  <InputLabel className="text-start fs-15 text-black font-skmodernist fw-bold">
                    Social Links
                  </InputLabel>
                </Grid>
                <Grid container spacing={2}>
                  {socialLinks &&
                    socialLinks.map((s) => (
                      <Grid
                        item
                        key={s.id}
                        className="d-flex flex-column justify-content-start align-items-center mb-2"
                      >
                        <div className="d-flex flex-column align-items-center">
                          <a
                            href={s.social_url}
                            target="_blank"
                            style={{ marginBottom: '2px' }}
                            rel="noopener noreferrer"
                            onClick={() => window.open(s.social_url, '')}
                          >
                            <img
                              src={`${SOCIAL_MEDIA_IMAGE_PATH}/${s.social_media_master.media_image_path}`}
                              style={{
                                width: '38px',
                                height: '38px',
                              }}
                            />
                          </a>
                          <InputLabel className="text-center w-100 fs-15 text-black font-skmodernist">
                            {s.social_name}
                          </InputLabel>
                        </div>
                      </Grid>
                    ))}
                </Grid>
              </Grid>
              <Grid
                item
                md={6}
                className="d-flex justify-content-between align-items-end"
              >
                <InputLabel className="text-start w-50 fs-15 text-black font-skmodernist fw-bold">
                  Buisness Card Generated
                </InputLabel>
                <Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">
                  {cardSharing ? 'Yes' : 'No'}
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                className="d-flex justify-content-between align-items-end"
              >
                <InputLabel className="text-start w-50 fs-15 text-black font-skmodernist fw-bold">
                  Website Created
                </InputLabel>
                <Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">
                  {isSellerWebsite ? (
                    <Link
                      rel="noopener noreferrer"
                      href={seller_websites && seller_websites[0]?.url}
                      target="_blank"
                    >
                      {seller_websites && seller_websites[0]?.url}
                    </Link>
                  ) : (
                    'No'
                  )}
                </Typography>
              </Grid>
              {/* <Grid
                item
                md={4}
                className="d-flex justify-content-between align-items-center mb-2"
              >
                <InputLabel className="text-start w-50 fs-15 text-black font-skmodernist">
                  Seller Website
                </InputLabel>
                <Link
                  component={Button}
                  style={{ textTransform: 'lowercase' }}
                  onClick={() => {
                    if (seller_websites?.length > 0) {
                      window.open(seller_websites[0]?.url, '_blank');
                    }
                  }}
                  className="text-grey w-50 fs-15 text-grey font-skmodernist"
                >
                  {seller_websites?.length > 0 ? seller_websites[0]?.url : ''}
                </Link>
              </Grid> */}
            </Grid>

            <Divider className="main-divider my-3"></Divider>
            <Grid container columnSpacing={4} rowSpacing={4}>
              {businessDetails &&
                businessDetails.map((r) => (
                  <Grid
                    item
                    md={6}
                    className="d-flex justify-content-between align-items-end mb-2"
                    key={r.document_name}
                  >
                    <InputLabel className="text-start w-50 fs-15 text-black font-skmodernist">
                      {r.document_name}
                    </InputLabel>
                    <Typography className="text-grey w-50 fs-15 text-grey font-skmodernist">
                      {r.document_number}
                    </Typography>
                  </Grid>
                ))}
            </Grid>
          </Box>
          <Grid
            container
            rowSpacing={1.5}
            columnSpacing={{ xs: 1, sm: 2, md: 2 }}
            className="align-items-start justify-content-between mb-4"
          >
            <Grid item xs={10} md={5} className="" rowSpacing={4}></Grid>

            <Grid item xs={10} md={5} className="" rowSpacing={4}></Grid>
          </Grid>

          <Typography className="fs-20 fw-bold text-black font-skmodernist">
            {productType} Details
          </Typography>
          <Divider className="main-divider my-3"></Divider>

          <Paper sx={{ width: '100%' }}>
            {products && products?.length > 0 && (
              <TableContainer sx={{ maxHeight: '450px' }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{productType} Name</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {products.map((row) => (
                      <TableRow>
                        <TableCell>
                          <div className="d-flex justify-content-start align-items-center">
                            <div className="w-50 d-flex">
                              <div className="me-3">
                                <img
                                  src={`${PRODUCT_IMAGE_BASE_URL}${row.default_image}`}
                                  alt="product-image"
                                  width="80"
                                  height="80"
                                  style={{
                                    objectFit: 'cover',
                                    objectPosition: 'center',
                                  }}
                                  className="product-image"
                                />
                              </div>
                              <div className="">
                                <Typography
                                  variant="h5"
                                  className="fs-16 text-black mb-2 fw-normal font-skmodernist"
                                  component="div"
                                >
                                  {row.title}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  className="fs-14 text-grey mb-2 font-gotham"
                                >
                                  {/* {row.description}. */}
                                  <span
                                    style={{
                                      width: '175px',
                                      display: 'inline-block',
                                    }}
                                  >
                                    {truncateText(row.description)}{' '}
                                  </span>
                                  {row?.description?.length > 50 ? (
                                    <Button
                                      aria-describedby={idd}
                                      className="ps-2 justify-content-start btn-tooltip"
                                      onClick={(e) =>
                                        handleClickPopper(e, row.description)
                                      }
                                    >
                                      <InfoOutlinedIcon
                                        sx={{
                                          width: '19px',
                                          height: '19px',
                                          color: '#864FFD',
                                        }}
                                      />
                                    </Button>
                                  ) : (
                                    <></>
                                  )}
                                </Typography>
                              </div>
                            </div>

                            <div className="w-50">
                              <Typography
                                variant="h5"
                                className="fs-13 text-darkgrey mb-0 font-skmodernist"
                              >
                                Starting at
                              </Typography>

                              <Typography
                                variant="h5"
                                className="fs-15 text-secondary fw-bold mb-2 font-skmodernist"
                              >
                                ₹ {row.budget}
                                {row.unit ? '/' : ''}
                                <span className="fs-13 text-grey fw-light">
                                  {row.unit}
                                </span>
                              </Typography>
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            {/* <CustomPagination page={page} count={data.length} rowsPerPage={rowsPerPage} onPageChange={handleChangePage} /> */}
          </Paper>
        </Box>
      </Box>
      <Popover
        id={idd}
        open={openPopper}
        anchorEl={popper}
        onClose={handleClosePopper}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box sx={{ p: 2, maxWidth: '300px' }}>
          <Typography
            className="fs-12 fw-normal font-gotham text-black"
            sx={{ wordBreak: 'break-all' }}
          >
            {popperText}
          </Typography>
        </Box>
      </Popover>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleInfIconClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Typography sx={{ p: 2 }}>
          {rejected_reason ? rejected_reason : 'No reason given'}.
        </Typography>
      </Popover>
    </SideBarContent>
  );
}
const mapStateToProps = ({ b2bUser, signin }) => {
  // const userId = b2bUser.b2bUser.user.id
  // const regId = signin.userData.registration.id

  return {
    b2bUser: b2bUser.b2bUser,
    b2bloading: b2bUser.loading,
    regId: b2bUser.b2bUser.id,
    status: b2bUser.b2bUser.status,
    rejected_reason: b2bUser.b2bUser.rejected_reason,
  };
};

export default connect(mapStateToProps, {
  updateVendorStatus,
  pushMessage,
  getVendorDetails,
})(B2BProfileView);
