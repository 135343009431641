import {
  EDIT_ENQUIRY_DATA,
  GET_ENQUIRY_DATA,
  SET_CLEAR_DATA,
  SET_ENQUIRY_FILTER,
  STATUS_DATA,
  GET_TOTAL_ENQUIRY,
  GET_TOTAL_USER_CURRENT_PAGE,
} from '../types';

const initial_state = {
  all_enquiry: [],
  total_enquiry: 0,
  total_enquiry_currentPage: 1,
  enquiryObj: {},
  all_enquiry_filter: [],
  status_data: {},
};

const enquiryListData = (state = initial_state, action) => {
  switch (action.type) {
    case GET_ENQUIRY_DATA:
      return {
        ...state,
        all_enquiry: action.payload,
      };
    case GET_TOTAL_ENQUIRY:
      return {
        ...state,
        total_enquiry: action.payload,
      };
    case GET_TOTAL_USER_CURRENT_PAGE:
      return {
        ...state,
        total_enquiry_currentPage: action.payload,
      };
    case EDIT_ENQUIRY_DATA:
      return {
        ...state,
        enquiryObj: action.payload,
      };
    case SET_CLEAR_DATA:
      return {
        ...state,
        enquiryObj: initial_state.enquiryObj,
      };
    case SET_ENQUIRY_FILTER:
      return {
        ...state,
        all_enquiry_filter: action.payload,
      };
    case STATUS_DATA:
      return {
        ...state,
        status_data: action.payload,
      };
    default:
      return state;
  }
};

export default enquiryListData;
