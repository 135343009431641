import moment from 'moment';
import { EnquiryListAPI } from '../../../../apis/EnquiryListAPI';
import { SET_LOADING } from '../../../../store/types';
import {
  EDIT_ENQUIRY_DATA,
  GET_ENQUIRY_DATA,
  SET_CLEAR_DATA,
  SET_ENQUIRY_FILTER,
  STATUS_DATA,
  GET_TOTAL_ENQUIRY,
  GET_TOTAL_USER_CURRENT_PAGE,
} from '../types';

export const getEnquiryDetails = (page, pageSize) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    try {
      const response = await EnquiryListAPI.getEnquiry(page, pageSize);
      if (response.status === 200) {
        // console.log('getEnquiryDetails response?.data', response?.data);

        const sortedArray = response?.data?.data?.sort(
          (a, b) => moment(b.updatedAt) - moment(a.updatedAt)
        );
        dispatch({ type: GET_ENQUIRY_DATA, payload: sortedArray });
        dispatch({
          type: GET_TOTAL_ENQUIRY,
          payload: response?.data?.totalRecords,
        });
        dispatch({
          type: GET_TOTAL_USER_CURRENT_PAGE,
          payload: page,
        });
        dispatch({ type: SET_LOADING, payload: false });
      } else {
        dispatch({ type: SET_LOADING, payload: false });
      }
    } catch (error) {
      dispatch({ type: SET_LOADING, payload: false });
      console.log('error', error);
    }
  };
};

export const editEnquiryData = (
  id,
  data,
  header,
  successCallback = () => {},
  errorCallback = () => {}
) => {
  return async (dispatch) => {
    try {
      const response = await EnquiryListAPI.updateEnquiryData(id, data, header);
      if (response.status === 200) {
        successCallback(response?.data);
        dispatch({ type: SET_LOADING, payload: false });
      } else {
        dispatch({ type: SET_LOADING, payload: false });
        errorCallback(response?.data);
      }
    } catch (error) {
      dispatch({ type: SET_LOADING, payload: false });
      if (error.response.status === 401) {
        errorCallback(error?.response?.data?.message);
      } else {
        errorCallback(error?.response?.data?.error);
      }
    }
  };
};

export const setEnquiryData = (fetch) => {
  return (dispatch) => {
    dispatch({ type: EDIT_ENQUIRY_DATA, payload: fetch });
  };
};

export const setClearEnquiryData = () => {
  return (dispatch) => {
    dispatch({ type: SET_CLEAR_DATA });
  };
};

export const setEnquiryFilter = (filter) => {
  return (dispatch) => {
    dispatch({ type: SET_ENQUIRY_FILTER, payload: filter });
  };
};

export const setStatusData = (status) => {
  return (dispatch) => {
    dispatch({ type: STATUS_DATA, payload: status });
  };
};

export const getStatusDetails = () => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    try {
      const response = await EnquiryListAPI.getStatusEnquiryData();
      if (response.status === 200) {
        dispatch({ type: STATUS_DATA, payload: response?.data?.module });
        dispatch({ type: SET_LOADING, payload: false });
      } else {
        dispatch({ type: SET_LOADING, payload: false });
      }
    } catch (error) {
      dispatch({ type: SET_LOADING, payload: false });
      console.log('error', error);
    }
  };
};
