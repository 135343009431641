import api from './configs/axiosConfig';
import {
  GET_VENDORS,
  DOWNLOAD_PDF,
  GET_VENDORS_DETAILS,
  UPDATE_VENDORS_DETAILS,
  DELETE_USER,
  GET_VENDORS_Reg_DETAILS,
  GET_SOCIAL_LINKS,
  SEARCH_VENDOR,
  BUSINESS_POST,
} from './endpoints';
export const Vendors = {
  GetVendors: async function (type, page, pageSize) {
    const response = await api.get(
      `${GET_VENDORS}/${type}?page=${page}&pageSize=${pageSize}`
    );
    return response;
  },
  SearchVendors: async function (vendorType = 0, page, pageSize, search_text) {
    //lucknow?pageSize=10&page=2
    const response = await api.get(
      `${SEARCH_VENDOR}${vendorType}/${search_text}?page=${page}&pageSize=${pageSize}`
    );
    return response;
  },
  DownloadPdf: async function (query) {
    const response = await api.get(`${DOWNLOAD_PDF}${query}`);
    return response;
  },
  GetVendorDetail: async function (reg_id) {
    const response = await api.get(`${GET_VENDORS_DETAILS}/${reg_id}`);
    return response;
  },
  GetVendorRegDetail: async function (user_id) {
    const response = await api.get(`${BUSINESS_POST}/${user_id}`);
    return response;
  },
  GetSocialLinks: async function (reg_id) {
    const response = await api.get(`${GET_SOCIAL_LINKS}/${reg_id}`);
    return response;
  },
  updateVendorStatus: async function (reg_id, data) {
    const response = await api.put(`${UPDATE_VENDORS_DETAILS}/${reg_id}`, data);
    return response;
  },
  deleteVendor: async function (user_id) {
    const response = await api.delete(`${DELETE_USER}/${user_id}`);
    return response;
  },
};
