import moment from 'moment';
import { RequirementListAPI } from '../../../../apis/RequirementListAPI';
import { SET_LOADING } from '../../../../store/types';
import {
  GET_REQUIREMENT_DATA,
  SET_REQUIREMENT_FILTER,
  STATUS_DATA,
  EDIT_REQUIREMENT_DATA,
  SET_CLEAR_DATA,
  GET_TOTAL_REQUIREMENT,
  REQUIREMENT_CURRENT_PAGE,
} from '../types';
export const getRequirementDetails = (page, pageSize) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    try {
      const response = await RequirementListAPI.getRequirement(page, pageSize);
      if (response.status === 200) {
        const sortedArray = response?.data?.data?.sort(
          (a, b) => moment(b.updatedAt) - moment(a.updatedAt)
        );
        dispatch({ type: GET_REQUIREMENT_DATA, payload: sortedArray });
        dispatch({ type: SET_LOADING, payload: false });
        dispatch({
          type: GET_TOTAL_REQUIREMENT,
          payload: response?.data?.totalRecords,
        });
        dispatch({
          type: REQUIREMENT_CURRENT_PAGE,
          payload: page,
        });
      } else {
        dispatch({ type: SET_LOADING, payload: false });
      }
    } catch (error) {
      dispatch({ type: SET_LOADING, payload: false });
      console.log('error', error);
    }
  };
};

export const setRequirementFilter = (filter) => {
  return (dispatch) => {
    dispatch({ type: SET_REQUIREMENT_FILTER, payload: filter });
  };
};

export const getStatusDetails = () => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    try {
      const response = await RequirementListAPI.getStatusRequirementData();
      if (response.status === 200) {
        dispatch({ type: STATUS_DATA, payload: response?.data?.module });
        dispatch({ type: SET_LOADING, payload: false });
      } else {
        dispatch({ type: SET_LOADING, payload: false });
      }
    } catch (error) {
      dispatch({ type: SET_LOADING, payload: false });
      console.log('error', error);
    }
  };
};

export const setRequirementData = (fetch) => {
  return (dispatch) => {
    dispatch({ type: EDIT_REQUIREMENT_DATA, payload: fetch });
  };
};

export const setStatusData = (status) => {
  return (dispatch) => {
    dispatch({ type: STATUS_DATA, payload: status });
  };
};

export const setClearRequirementData = () => {
  return (dispatch) => {
    dispatch({ type: SET_CLEAR_DATA });
  };
};

export const editRequirementData = (
  id,
  data,
  header,
  successCallback = () => {},
  errorCallback = () => {}
) => {
  return async (dispatch) => {
    try {
      const response = await RequirementListAPI.updateRequirementData(
        id,
        data,
        header
      );
      if (response.status === 200) {
        successCallback(response?.data);
        dispatch({ type: SET_LOADING, payload: false });
      } else {
        dispatch({ type: SET_LOADING, payload: false });
        errorCallback(response?.data);
      }
    } catch (error) {
      dispatch({ type: SET_LOADING, payload: false });
      if (error.response.status === 401) {
        errorCallback(error?.response?.data?.message);
      } else {
        errorCallback(error?.response?.data?.error);
      }
    }
  };
};
